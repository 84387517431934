import { Stack, IStackTokens } from '@fluentui/react';
import { FunctionComponent, useState } from 'react';
import { BarcodeScanner, DetectedBarcode } from 'react-barcode-scanner'
import 'react-barcode-scanner/polyfill'

const stackTokens: IStackTokens = { childrenGap: 40 };

export const ScanComponent: FunctionComponent = () => {
    const [data, setData] = useState<string|null>(null);

    return (
        <Stack tokens={stackTokens}>
            <BarcodeScanner
                options={{
                    formats: [
                        'code_128',
                        'code_93',
                        'code_39',
                        'ean_8',
                        'ean_13',
                        'upc_a',
                        'upc_e',
                        'qr_code',
                        'itf'
                    ]
                }}
                onCapture={(barcode: DetectedBarcode) => {
                    setData(barcode.rawValue + ', ' + barcode.format)
                }}
            />
            <p>
                {data}
            </p>
        </Stack>
    );
};